module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"sra-portal","short_name":"sra-portal","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/logos/SRA-Favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a0fd54ce3d11a99873e1e5e5acf951e0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"sra-portal","accessToken":"MC5ZSkhyM1JFQUFDRUFLRHRV.A--_ve-_vQTvv73vv70wW--_vRDvv73vv73vv73vv70U77-9I0fvv70r77-9ZE_vv70VaO-_vUtr77-9NO-_vQ","lang":"en-us","promptForAccessToken":true,"apiEndpoint":"https://sra-portal.cdn.prismic.io/api/v2","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
